import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<SuccessComponent>,
        @Inject(MAT_DIALOG_DATA) private successData: string
    ) {}

    /**
     * @param {string} successMessage le message de succes a afficher.
     */
    successMessage: string;

    /**
     * Ferme la fenêtre de succes.
     */
    closeDialog() {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.successMessage = this.successData
            ? this.successData
            : "L'action a été réalisée avec succès.";
    }
}
