<div class="header">
    <span class="icon-easi-call-line"></span>
    <span class="label">M'inscrire à un call</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <div class="header-content">
        <span class="calls-count"
            ><strong>{{ callsCount }}</strong> calls</span
        >
        <div class="structure-container" *ngIf="showStructuresDropdown()">
            <lib-uimm-dropdown
                [(items)]="structures"
                (itemsChange)="updateStructure($event)"
                [label]="'Structures'"
                [labelWidth]="'100%'"
                [maxWidth]="500"
                [maxHeight]="250"
                [design]="'boxes'"
                [headers]="{ search: true }"
            ></lib-uimm-dropdown>
        </div>
        <div class="search-container">
            <input
                class="input-container"
                type="text"
                placeholder="Chercher..."
                [(ngModel)]="searchTerm"
                (keyup.enter)="refreshData()"
            />
            <span class="icon-search" (click)="refreshData()"></span>
        </div>
    </div>
    <div class="top">
        <span class="name">Nom</span>
        <span class="author">Auteur</span>
        <span class="members">Inscrits</span>
    </div>
    <div
        class="call-container"
        *ngIf="calls && calls.length"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="nextPage()"
    >
        <app-subscribe-call-entry
            *ngFor="let call of calls"
            [call]="call"
            [selected]="isCallSelected(call)"
            (select)="addToSubscribedCall($event)"
        ></app-subscribe-call-entry>
    </div>
</mat-dialog-content>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{ disabled: !canSubscribeToCall() }"
        (click)="subscribeToCalls()"
    >
        Valider
    </div>
</div>
