<div class="container" [ngClass]="{ unselected: !isElementSelected() }">
    <div
        class="select-box"
        (click)="toggleSelected()"
        [ngClass]="{ 'icon-select': !isElementSelected(), 'icon-selected': isElementSelected() }"
    ></div>
    <div class="icon" [ngClass]="getIconStyle()"></div>
    <div class="name">{{ getElementName() }}</div>
    <div class="role" *ngIf="isElementSelected()">
        <span
            class="icon icon-droit-lecture"
            *ngIf="isAttendee()"
            tooltip="Participant"
            tooltipPosition="left"
            tooltipColor="#f36"
            (click)="toggleRole()"
        ></span>
        <span
            class="icon icon-droit-ecriture"
            *ngIf="isModerator()"
            tooltip="Modérateur"
            tooltipPosition="left"
            tooltipColor="#f36"
            (click)="toggleRole()"
        ></span>
    </div>
    <div
        class="icon-down"
        [ngClass]="{ opened: isGroupOpened() }"
        *ngIf="element.name"
        (click)="showGroup()"
    ></div>
    <div
        class="group-container"
        *ngIf="element.name"
        [@slideToggle]="!openedGroup ? 'closed' : 'open'"
    >
        <div class="name-container" *ngFor="let user of element.users">
            <span [ngClass]="getUserIcon(user)"></span>
            <span class="name">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span>
        </div>
    </div>
</div>
