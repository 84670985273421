import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { CallService } from '../../../services/call.service';
import { UsersService } from '../../../services/users.service';
import { DialogService } from '../../../services/dialog.service';
import { Subscription } from 'rxjs';
import { slideToggle } from 'src/app/animations/slide-toggle';

/**
 * Composant gérant l'affichage des calls dans la fenêtre d'enregistrement des calls
 */
@Component({
    selector: 'app-subscribe-call-entry',
    templateUrl: './subscribe-call-entry.component.html',
    styleUrls: ['./subscribe-call-entry.component.scss'],
    animations: [slideToggle]
})
export class SubscribeCallEntryComponent implements OnInit, OnDestroy {
    @Input() call: any;
    @Input() selected: boolean;

    @Output() select: EventEmitter<any> = new EventEmitter();

    openedCall: boolean;

    subscriptions = new Subscription();

    constructor(
        private callService: CallService,
        private userService: UsersService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this.openedCall = false;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Indique l'icône à afficher selon le type et le rôle de l'utilisateur
     * @param {any} user Un membre du call actuel
     * @returns {string} La classe CSS correspondante à l'icône à afficher
     */
    getUserIcon(user: any): string {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tuteurentreprise) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     * Indique le nom de l'auteur du call
     * @returns {string} Le prénom et le nom de l'auteur du call
     */
    getAuthorName(): string {
        return this.call.owner.lastname.toUpperCase() + ' ' + this.call.owner.firstname;
    }

    /**
     * Envoie les informations du call au composant parent
     */
    emitEvent() {
        const data = {
            selected: this.selected,
            id: this.call.id
        };
        this.select.emit(data);
    }

    /**
     * Sélectionne/déselectionne le call
     */
    toggleSelected() {
        this.selected = !this.selected;
        this.emitEvent();
    }

    /**
     * Indique si le call actuel est sélectionné
     * @returns {Boolean} True si le call actuel est sélectionné, faux dans le cas contraire
     */
    isCallSelected(): boolean {
        return this.selected;
    }

    /**
     * Indique si le call actuel est ouvert
     * @returns {Boolean} True si le call actuel est ouvert, faux dans le cas contraire
     */
    isCallOpened(): boolean {
        return this.openedCall;
    }

    /**
     * Ouvre et affiche le call actuel
     * @param {Event} $event L'évenement JS émis lors du clic
     */
    showCall($event: Event) {
        if (this.call.users === undefined) {
            this.subscriptions.add(
                this.callService.getCall(this.call.id).subscribe(
                    (data: any) => {
                        for (const i in data.users) {
                            if (data.users[i].id === this.call.owner.id) {
                                data.users.splice(i, 1);
                            }
                        }
                        this.call.users = data.users;
                        this.call.groups = data.groups;

                        this.openedCall = !this.openedCall;
                        for (const i in data.groups) {
                            if (data.groups[i].users === undefined) {
                                this.subscriptions.add(
                                    this.userService.getGroup(data.groups[i].id).subscribe(
                                        (data_group: any) => {
                                            data.groups[i].users = data_group.users;
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.dialogService.openErrorDialog(
                                                error.error.userMessage
                                            );
                                        }
                                    )
                                );
                            }
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                )
            );
        } else {
            this.openedCall = !this.openedCall;
        }
    }
}
