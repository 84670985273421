<div class="container" [ngClass]="{ unselected: !isCallSelected() }">
    <div
        class="select-box"
        (click)="toggleSelected()"
        [ngClass]="{ 'icon-select': !isCallSelected(), 'icon-selected': isCallSelected() }"
    ></div>
    <div class="main-container">
        <div class="call-name">
            {{ call.name }}
            (le {{ call.starttime | date: 'dd/MM/yyyy' }} à {{ call.starttime | date: 'HH' }}h{{
                call.starttime | date: 'mm'
            }})
        </div>
        <div class="author-container">
            <span class="icon" [ngClass]="getUserIcon(call.owner)"></span>
            <span class="author-name">{{ getAuthorName() }}</span>
        </div>
        <div class="members-count">{{ call.members_count - 1 }}</div>
    </div>

    <span
        class="icon-down"
        [ngClass]="{ opened: isCallOpened() }"
        (click)="showCall($event)"
    ></span>

    <div id="call-container" [@slideToggle]="openedCall ? 'open' : 'closed'">
        <div class="name-user-container" *ngFor="let user of call.users">
            <span class="icon" [ngClass]="getUserIcon(user)"></span>
            <span class="name">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span>
        </div>
        <div class="name-group-container" *ngFor="let group of call.groups">
            <div class="group-header">
                <span class="icon-groupe"></span>
                <span class="name">{{ group.name }}</span>
            </div>
            <div class="users-container" *ngFor="let user of group.users">
                <span class="icon-apprenant"></span>
                <span class="name-user"
                    >{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span
                >
            </div>
        </div>
    </div>
</div>
