import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ConfigService } from './config.service';

import { CookieService } from 'ngx-cookie-service';
import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class LoginService {
    /**
     * L'utilisateur actuel
     */
    user: any;
    lastStructure: any = {};
    closeAllDialogs$: Subject<any> = new Subject();

    moderators: Array<number>;

    updateStructure: Subject<any> = new Subject();

    structureListener: any;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private cookieService: CookieService
    ) {}

    /**
     * Renvoie l'utilisateur actuel
     * @returns {any} Un objet représentant l'utilisateur actuel tel que renvoyé par getCurrentUser
     */
    getUser(): any {
        return this.user;
    }

    @log() getStructures(): Observable<Array<any>> {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage('getStructures', '*');

        return new Observable((observer) => {
            if (!this.structureListener) {
                this.structureListener = window.addEventListener(
                    'message',
                    (message) => {
                        if (message.data.structures) {
                            observer.next(message.data.structures);
                            observer.complete();
                        }
                    },
                    false
                );
            }
        });
    }

    /**
     * @returns {any} la derniere structure selectionnee par l'utilisateur.
     */
    getLastStructure(): any {
        return this.lastStructure;
    }

    /**
     *  modifie l'id de la nom de la derniere structure qui avait ete selectionee par l'utilisateur.
     * @param {any} structure recue via un cookie.
     * @returns {void}
     */
    setLastStructure(structure: any): void {
        if (structure.structureid) {
            this.lastStructure.id = structure.structureid;
            this.lastStructure.name = structure.structurename;
        } else if (structure.id) {
            this.lastStructure.id = structure.id;
            this.lastStructure.name = structure.name;
        }
        this.updateStructure.next(this.lastStructure);
    }

    /**
     *  update la structure et poste le message a easi-shared.
     * @returns {void}
     */
    updateLastStructure(structure: any): void {
        const iframe: any = document.getElementById('header-container');

        this.setLastStructure(structure);
        iframe.contentWindow.postMessage(
            { setCookies: { structureid: structure.id, structurename: structure.name } },
            '*'
        );
    }

    /**
     * Indique si l'utilisateur actuel est un apprenant
     * @returns {boolean} True si l'utilisateur actuel est un apprenant, faux dans le cas contraire
     */
    isLearner(): boolean {
        if (this.user) {
            return this.user.roles.learner || this.user.roles.prospect;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur
     * @returns {boolean} True si l'utilisateur actuel est un administrateur, faux dans le cas contraire
     */
    isAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin || this.user.roles.localAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur national
     * @returns {boolean} True si l'utilisateur actuel est un administrateur national, faux dans le cas contraire
     */
    isNationalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin;
        }
    }

    isCallModerator(callId: number): boolean {
        if (this.moderators) {
            return this.moderators.indexOf(callId) > -1;
        }
    }

    addAsModerator(callId: number) {
        this.moderators.push(callId);
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getCurrent(): Observable<any> {
        const iframe: any = document.getElementById('header-container');

        iframe.contentWindow.postMessage('getCurrent', '*');

        return fromEvent(window, 'message').pipe(
            filter((data: any) => {
                return data.data.current !== undefined;
            }),
            tap({
                error: (error) => {
                    this.isAuthenticated(error);
                }
            })
        );
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getCurrentUserCall(): Observable<any> {
        return this.http.get('/user/current').pipe(
            tap(
                (data) => {
                    if (this.cookieService.check('redirect-url')) {
                        const redirectUrl = this.cookieService.get('redirect-url');
                        this.cookieService.delete('redirect-url');
                        window.location.href = redirectUrl;
                        window.location.reload();
                    }
                    this.user = data;
                    this.getModerators().subscribe((moderators: Array<number>) => {
                        this.moderators = moderators;
                    });
                },
                (error) => {
                    const expires = new Date();
                    expires.setFullYear(expires.getFullYear() + 1);
                    this.cookieService.set(
                        'redirect-url',
                        window.location.href,
                        expires,
                        '/',
                        '',
                        true,
                        'None'
                    );
                }
            )
        );
    }

    @log() getModerators(): Observable<any> {
        return this.http.get('/reservations/moderator');
    }

    @log() postCookies() {
        return this.http.post('/setTestCookie', undefined);
    }

    @log() getCookies() {
        return this.http.get('/getTestCookie');
    }

    isAuthenticated(error: HttpErrorResponse) {
        if (error.error.errorCode && error.error.errorCode === 'USER_NOT_AUTH') {
            window.location.href = this.configService.getRedirectEndPoint();
        }
    }

    initCheckConnectedInterval() {
        setInterval(() => {
            this.http.get<boolean>(`/is_still_connected.php`).subscribe((data: boolean) => {
                if (data === false) {
                    const iframe: HTMLIFrameElement = document.getElementById(
                        'header-container'
                    ) as HTMLIFrameElement;
                    iframe.contentWindow.postMessage('userDisconnected', '*');
                    this.closeAllDialogs$.next(true);
                }
            });
        }, 5 * 60 * 1000);
    }
}
