<div class="header">
    <span class="icon-Warning"></span>
    <span class="label">Suppression d'un call</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    Vous vous apprêtez à supprimer un call.<br />
    <strong>Cette suppression sera définitive et irrévocable</strong>
</mat-dialog-content>
<div class="footer">
    <div class="delete-button" (click)="deleteCall()">
        <span *ngIf="!setWaiting">Supprimer le call</span>
        <div class="loadingEntry" *ngIf="setWaiting">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </div>
</div>
