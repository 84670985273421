import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { UsersService } from '../../services/users.service';
import { CallService } from '../../services/call.service';
import { LoginService } from '../../services/login.service';

@Component({
    selector: 'app-shared-call',
    templateUrl: './shared-call.component.html',
    styleUrls: ['./shared-call.component.scss']
})
export class SharedCallComponent implements OnInit, OnDestroy {
    isLoaded = false;
    sharedCallsLoaded = false;
    sharedCalls: Array<any>;
    adminCallsLoaded = false;
    flatList: Array<any>;
    call: Array<any>;
    headerText: string;

    openErrorDialog: Subject<string> = new Subject();

    subscriptions = new Subscription();

    constructor(
        private router: Router,
        private callService: CallService,
        private usersService: UsersService,
        private loginService: LoginService,
        public dialogRef: MatDialogRef<SharedCallComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        const params = {
            type: this.data.type,
            instanceid: this.data.id
        };

        this.subscriptions.add(
            this.callService.getSharedCall(params).subscribe((data: any) => {
                this.call = data;
                this.isLoaded = true;
                if (this.call.length === 0) {
                    this.openCreateCall();
                }
            })
        );
        if (this.data.type === 'group') {
            this.headerText = `Calls partagés avec ce groupe`;
        } else if (this.data.type === 'user') {
            this.headerText = `Calls partagés avec cet utilisateur`;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    openSharedCall(call) {
        this.dialogRef.close(call);
    }

    /**
     * Indique si le menu de sélection de structure doit s'afficher pour l'utilisateur actuel
     * @returns {Boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    showStructuresDropdown(): boolean {
        return this.loginService.isNationalAdmin();
    }

    openCreateCall() {
        const queryParams = [];

        queryParams['new'] = 'true';
        queryParams[this.data.type] = this.data.id;

        this.router.navigate(['.'], { queryParams: queryParams });

        this.dialogRef.close();
    }

    /**
     * Ferme la fenêtre actuelle
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
