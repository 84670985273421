import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Call } from '../structures/call';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    constructor(private http: HttpClient) {}

    /**
     * Récupère la liste des calls accessibles par l'utilisateur actuel
     * @returns {Observable} Un observable de la liste des calls de l'utilisateur courant
     */
    @log() getHistory(
        body: {
            with_recording?: boolean;
            startdate: Date;
            enddate: Date;
            limit: number;
            offset: number;
        },
        search: string
    ): Observable<Call[]> {
        if (body.hasOwnProperty('with_recording') && body.with_recording === false) {
            delete body.with_recording;
        }

        if (search === undefined) {
            search = '';
        }

        return this.http.post<Call[]>('/reservations/history/list', body, { params: { search } });
    }
}
