<div class="container" [ngClass]="{ owner: isOwner() }">
    <span class="icon" [ngClass]="getRoleIcon()"></span>
    <span *ngIf="type === 'user'" class="name"
        >{{ element.lastname.toUpperCase() }} {{ element.firstname }}</span
    >
    <span *ngIf="type === 'group'" class="name">{{ element.name }}</span>
    <span *ngIf="type === 'guest'" class="name guest-name">{{ element }}</span>
    <span class="joined" *ngIf="joinedCall(this.element.id)">a rejoint le call</span>
    <div class="role">
        <span
            class="icon icon-droit-ecriture"
            *ngIf="isModerator()"
            tooltip="Modérateur"
            tooltipPosition="left"
            tooltipColor="#f36"
        ></span>

        <span
            class="icon icon-droit-lecture"
            *ngIf="!isModerator()"
            tooltip="Participant"
            tooltipPosition="left"
            tooltipColor="#f36"
        ></span>
    </div>
    <div class="owner" *ngIf="isOwner()">Organisateur</div>
    <div class="guest" *ngIf="type === 'guest'">Invité</div>
    <div class="group-container" *ngIf="type === 'group'">
        <div class="name-container" *ngFor="let user of element.users">
            <span [ngClass]="getUserIconStyle(user)"></span>
            <span class="name-learner">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span>
            <span class="joined" *ngIf="joinedCall(user.id)">a rejoint le call</span>
        </div>
    </div>
</div>
