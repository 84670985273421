<div class="container" [ngClass]="{ selected: isCallSelected() }">
    <div class="icon-easi-call-line"></div>
    <div class="title">{{ call.name }}</div>
    <div class="date" [ngClass]="{ show: type !== 'author' && type !== 'history' }">
        {{ call.starttime | date: 'dd/MM/yyyy' }}
    </div>
    <div
        class="duration"
        [ngClass]="{ show: type !== 'author' && type !== 'history' }"
        *ngIf="(!call.hasRecords || type !== 'history') && !isStarted"
    >
        {{ call.starttime | date: "HH'h'mm" }}-{{ call.endtime | date: "HH'h'mm" }}
    </div>
    <div
        class="started"
        [ngClass]="{ show: type !== 'author' && type !== 'history' }"
        *ngIf="isStarted"
    >
        En cours
    </div>
    <div class="icons">
        <span
            class="icon-Suividesconnexions"
            *ngIf="canExportPolls() && type === 'history'"
            tooltip="Export des sondages de présence"
            tooltipPosition="left"
            tooltipColor="#f36"
            (click)="exportPolls()"
        ></span>
        <span
            class="icon-download"
            *ngIf="call.video_urls?.length && type === 'history'"
            (click)="$event.stopImmediatePropagation()"
            tooltip="Télécharger l'enregistrement"
            tooltipPosition="left"
            tooltipColor="#f36"
            [matMenuTriggerFor]="downloadMenu"
            [matMenuTriggerData]="{ call: call }"
        ></span>
        <span
            class="icon-enregistrement"
            *ngIf="call.records !== 0 && type === 'history'"
            tooltip="Appel enregistré"
            tooltipPosition="left"
            tooltipColor="#f36"
        ></span>
        <span
            [ngClass]="getCallNotificationsIcon()"
            *ngIf="type !== 'history'"
            [tooltip]="getNotificationsIconTooltip()"
            tooltipPosition="left"
            tooltipColor="#f36"
            (click)="toggleCallNotifications($event, call)"
        ></span>
        <span
            class="icon-Cloner"
            tooltip="Dupliquer le call"
            tooltipPosition="left"
            tooltipColor="#f36"
            *ngIf="isAuthor()"
            (click)="openDuplicateCallDialog($event)"
        ></span>
        <span
            class="icon-Editer"
            tooltip="Modifier le call"
            tooltipPosition="left"
            tooltipColor="#f36"
            *ngIf="canOpenEditCall()"
            (click)="openEditCallDialog($event)"
        ></span>
        <ng-container *ngIf="type === 'author'">
            <span
                class="icon-message"
                tooltip="Envoyer un rappel aux invités"
                tooltipPosition="left"
                tooltipColor="#f36"
                (click)="remindCall()"
                *ngIf="!mailIsSent"
            ></span>

            <span
                class="icon-bin"
                *ngIf="displayBin()"
                tooltip="Supprimer le call"
                tooltipPosition="left"
                tooltipColor="#f36"
                (click)="openDeleteCallDialog($event)"
            ></span>
            <span
                class="icon-enregistrement"
                *ngIf="call.records !== 0"
                tooltip="Enregistré"
                tooltipPosition="left"
                tooltipColor="#f36"
            ></span>
        </ng-container>
    </div>
    <mat-menu #downloadMenu="">
        <ng-template matMenuContent let-call="call">
            <span
                mat-menu-item
                *ngFor="let video_url of call.video_urls; index as i"
                (click)="downloadVideo(video_url)"
            >
                Enregistrement {{ i + 1 }}
            </span>
        </ng-template>
    </mat-menu>
</div>
