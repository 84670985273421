import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ConfigService } from '../../services/config.service';
import { LoginService } from '../../services/login.service';

/**
 *  composant gérant l'affichage du bandeau easi-shared
 */
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer,
        private loginService: LoginService,
        private configService: ConfigService
    ) {}

    iframe_url: SafeResourceUrl;
    iframe_redirect_url: SafeResourceUrl;

    ngOnInit() {
        this.initResizeIframe();
        this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.configService.getEasiSharedEndPoint() + '/index.html'
        );
        this.iframe_redirect_url = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.configService.getIframeLogoutEndPoint()
        );
    }

    /**
     *  charge le script resize-iframe.js necessaire au fonctionnement d'easi-shared.
     * @returns {void}
     */
    initResizeIframe(): HTMLScriptElement {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = this.configService.getEasiSharedEndPoint() + '/resize-iframe.js';
        this.renderer.appendChild(this.element.nativeElement, script);
        return script;
    }

    isLoggedIn(): boolean {
        return !!this.loginService.getUser();
    }
}
