import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';

import { CallService } from '../../services/call.service';
import { UsersService } from '../../services/users.service';
import { LoginService } from '../../services/login.service';

/**
 * Composant gérant l'affichage de la fenêtre d'enregistrement à un call
 */
@Component({
    selector: 'app-subscribe-call',
    templateUrl: './subscribe-call.component.html',
    styleUrls: ['./subscribe-call.component.scss']
})
export class SubscribeCallComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<SubscribeCallComponent>,
        private callService: CallService,
        private usersService: UsersService,
        private loginService: LoginService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    /**
     * @param {Subject} openErrorDialog emet un evenement pour ouvrir une fenetre de dialogue en cas d'erreur.
     * @param {number} LIMIT limite d'entrees a afficher pour chaque page.
     * @param {Array<number>} calls la liste des calls disponibles.
     * @param {number} callsCount le nombre de calls disponibles au subscribe.
     * @param {number} currentPage la page courante ou se situe l'utilisateur.
     * @param {Array<any>} structures le tableau contenant les structures.
     * @param {any} selectedStructure la sutrcutre selectionnee.
     * @param {Array<number>} subscribedCalls id des calls souscrits.
     * @param {string} searchTerm la phrase recherchee.
     */
    openErrorDialog: Subject<string> = new Subject();

    subscriptions = new Subscription();

    LIMIT = 30;

    calls: Array<any>;
    callsCount: number;
    currentPage: number;

    structures: Array<any>;
    selectedStructure: any;

    subscribedCalls: Array<number>;

    searchTerm: string;

    ngOnInit() {
        this.calls = [];
        this.subscribedCalls = [];
        this.currentPage = -1;

        this.subscriptions.add(
            this.usersService.getStructures().subscribe(
                (data) => {
                    if (this.loginService.isNationalAdmin()) {
                        this.selectedStructure = this.loginService.getLastStructure();
                    } else {
                        this.selectedStructure = {
                            id: this.loginService.getUser().structureid,
                            name: this.loginService.getUser().localStructure
                        };
                    }
                    this.structures = data.data.structures.map((structure: any) => {
                        return {
                            key: structure.id,
                            title: structure.name,
                            selected: this.selectedStructure.id === structure.id
                        };
                    });
                    this.structures.unshift({ title: 'Administration nationale', key: 0 });
                    if (this.selectedStructure) {
                        this.refreshData();
                    }
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.next(error.error.userMessage);
                }
            )
        );

        this.initRoutes();

        this.subscriptions.add(
            this.loginService.updateStructure.subscribe((data) => {
                this.selectedStructure = data;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Indique si le menu de sélection de structure doit s'afficher pour l'utilisateur actuel
     * @returns {Boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    showStructuresDropdown(): boolean {
        return this.loginService.isNationalAdmin();
    }

    /**
     * Rafraichit la liste des calls
     */
    nextPage() {
        const params: any = {};
        this.currentPage++;

        params.structureid = this.selectedStructure.id;
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPage * this.LIMIT;
        params.limit = this.LIMIT;

        if (params.structureid !== undefined) {
            this.subscriptions.add(
                this.callService.getAdminCalls(params).subscribe(
                    (data: any) => {
                        this.calls = this.calls.concat(data);
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.next(error.error.userMessage);
                    }
                )
            );
        }
    }

    updateStructure($event: any) {
        this.structures = $event;
        for (const i in this.structures) {
            if (this.structures[i].selected) {
                this.selectedStructure = {
                    id: this.structures[i].key,
                    name: this.structures[i].title
                };
            }
        }
        this.refreshData();
    }

    /**
     * rafraichit les donnees lors d'une recherche, scroll de bas de page ou changement de seclectedStructure.
     */
    refreshData() {
        if (this.selectedStructure) {
            if (this.selectedStructure.id !== undefined && this.selectedStructure.id !== 0) {
                this.loginService.updateLastStructure(this.selectedStructure);
            }
            this.calls = [];
            this.currentPage = -1;
            this.nextPage();
            this.getAdminCallsNumber();
        }
    }

    /**
     * Ajoute/supprime un call à la liste des calls sélectionnés
     * @param {any} data Un objet contenant les champ suivants : id, selected
     */
    addToSubscribedCall(data: any) {
        if (data.selected) {
            this.subscribedCalls.push(data.id);
        } else {
            for (const i in this.subscribedCalls) {
                if (this.subscribedCalls[i] === data.id) {
                    this.subscribedCalls.splice(parseInt(i, 10), 1);
                }
            }
        }
    }

    /**
     * Indique si le call passé en paramètre est sélectionné ou non
     * @param {any} call Un objet représentant un call
     * @returns {Boolean} True si le call est sélectionné, false dans le cas contraire
     */
    isCallSelected(call: any): boolean {
        for (const i in this.subscribedCalls) {
            if (this.subscribedCalls[i] === call.id) {
                return true;
            }
        }
        return false;
    }

    getAdminCallsNumber() {
        const params: any = {};

        params.structureid = this.selectedStructure.id;

        if (params.structureid !== undefined) {
            this.callService.getAdminCallsCount(params).subscribe(
                (data: any) => {
                    this.callsCount = data.count;
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.next(error.error.userMessage);
                }
            );
        }
    }

    /**
     * Indique si le bouton de validation de l'inscription doit être activé ou non
     * @returns {Boolean} True si le bouton d'inscription doit etre activé, false dans le cas contraire
     */
    canSubscribeToCall(): boolean {
        return this.subscribedCalls.length > 0;
    }

    /**
     * S'enregistre aux calls présent dans la liste des calls sélectionnés
     */
    subscribeToCalls() {
        if (this.canSubscribeToCall()) {
            const body = {
                reservations: this.subscribedCalls
            };

            this.subscriptions.add(
                this.callService.subscribeCalls(body).subscribe(
                    (data: any) => {
                        this.subscribedCalls.map((callId) => {
                            this.loginService.addAsModerator(callId);
                        });
                        this.callService.refreshCalls();
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.next(error.error.userMessage);
                    }
                )
            );
        }
    }

    /**
     * Ferme la fenêtre d'enregistrement à un call
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * Initialise les calls sélectionnés selon les élements passé en paramètre
     */
    initRoutes() {
        if (this.data.callId) {
            this.subscriptions.add(
                this.callService.getCall(this.data.callId).subscribe(
                    () => {
                        this.addToSubscribedCall({ selected: true, id: this.data.callId });
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.next(error.error.userMessage);
                    }
                )
            );
        }
    }
}
