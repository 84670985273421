<div class="header">
    <span class="icon-easi-call-line"></span>
    <span class="label" [innerHTML]="headerText"></span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <ng-container *ngIf="isLoaded">
        <div class="content-header">
            <span class="call-count"
                ><b>{{ call?.length }}</b> calls</span
            >
            <div class="search-container">
                <input class="input-container" type="text" placeholder="Chercher..." />
                <span class="icon-search"></span>
            </div>
        </div>
        <div class="top">
            <span class="name">Nom</span>
            <span class="author">Auteur</span>
            <span class="members">Inscrits</span>
        </div>
        <div class="call-container">
            <app-shared-call-entry
                *ngFor="let call of call"
                [call]="call"
                (click)="openSharedCall(call)"
            ></app-shared-call-entry>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoaded">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </ng-container>
</mat-dialog-content>
<div class="footer">
    <div class="button" (click)="openCreateCall()">Créer un nouveau call</div>
</div>
