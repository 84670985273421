import { Component, OnInit, Input } from '@angular/core';

/**
 * Composant gérant l'affichage des groupes/utilisateurs dans la vue principal
 */
@Component({
    selector: 'app-main-entry',
    templateUrl: './main-entry.component.html',
    styleUrls: ['./main-entry.component.scss']
})
export class MainEntryComponent {
    /**
     * @param {any} element l'user recu en input.
     * @param {string} type le type de l'user recu.
     * @param {Array<string>} connected le tableau des user connectes.
     * @param {boolean} openedGroup .
     */
    @Input() element: any;
    @Input() type: string;
    @Input() connected: Array<string>;

    openedGroup: boolean;

    constructor() {}

    /**
     *  indique l'icône à afficher selon le type/rôle de l'f
     * @returns {string} la classe CSS à appliquer afin d'afficher l'icône correspondante
     */
    getRoleIcon(): string {
        if (this.type === 'group') {
            return 'icon-groupe';
        } else if (this.type === 'guest') {
            return '';
        } else {
            return this.getUserIconStyle(this.element);
        }
    }

    getUserIconStyle(user) {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     *  indique si l'élement actuel est modérateur du call
     * @returns {boolean} true si l'élement possède le rôle de modérateur, false dans le cas contraire
     */
    isModerator(): boolean {
        return this.element.role === 'moderator';
    }

    /**
     *  indique si l'élement actuel est organisateur du call
     * @returns {boolean} true si l'élement actuel est l'organisateur du call, false dans le cas contraire
     */
    isOwner(): boolean {
        return this.element.owner;
    }

    /**
     *  teste si l'user a rejoint le call.
     * @param {number} id l'id de l'user
     * @returns {boolean} true si l'user a rejoint le call
     */
    joinedCall(id: number): boolean {
        if (this.type === 'user') {
            for (const i in this.connected) {
                if (id === parseInt(this.connected[i], 10)) {
                    return true;
                }
            }
            return false;
        }
    }
}
