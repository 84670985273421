import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import saveAs from 'file-saver';

import { Call } from '../structures/call';
import { log } from './decorators/log.decorator';
import { map } from 'rxjs/operators';

/**
 * Service gérant tous les appels aux webservice liés aux call
 */
@Injectable({
    providedIn: 'root'
})
export class CallService {
    constructor(private http: HttpClient) {}

    /**
     * @param {Subject} refreshListCalls emet un evenemnt pour rafraichir la liste des calls.
     * @param {Subject} refreshCall emet un evenement lorsqu'on doit reset un call.
     * @param {Subject} resetCall emet un evenemnt lorsqu'on doit reset un call.
     */
    refreshListCalls: Subject<any> = new Subject();
    refreshCall: Subject<number> = new Subject();
    resetCall: Subject<any> = new Subject();

    CallsSubject: BehaviorSubject<any> = new BehaviorSubject([]);

    /**
     * Récupère les informations d'un call spécifique
     * @param {number} id L'identifiant du call à récuperer
     * @returns {Observable} Un observable du call récupéré
     */
    @log() getCall(id: number): Observable<any> {
        return this.http.get('/reservations/' + id, { params: {} });
    }

    /**
     * Récupère la liste des calls accessibles par l'utilisateur actuel
     * @returns {Observable} Un observable de la liste des calls de l'utilisateur courant
     */
    @log() getCalls(search: string): Observable<any> {
        return this.http.get('/reservations/list', { params: { search } });
    }

    @log() getCallsSubject() {
        return this.CallsSubject.asObservable();
    }

    /**
     * Récupère la liste des calls auxquels l'administrateur peut s'inscrire
     * @param {any} params Un objet contenant 2 paramètres : structureid et search
     * @returns {Observable} Un observable de la liste des calls correspondant aux paramètres
     */
    @log() getAdminCalls(params: any): Observable<any> {
        return this.http.get('/reservations/admin', { params });
    }

    /**
     * Récupère le nombre de calls auxquels l'administrateur peut s'inscrire
     * @param {any} params Un objet contenant 1 paramètre : structureid
     * @returns {Observable} Un observable du nombre de calls correspondant aux paramètres
     */
    @log() getAdminCallsCount(params: any): Observable<any> {
        return this.http.get('/reservations/admin?count=true', { params });
    }

    /**
     * Récupère la liste des calls accessibles par l'utilisateur actuel
     * @returns {Observable} Un observable de la liste des calls de l'utilisateur courant
     */
    @log() getSharedCall(params: any): Observable<any> {
        return this.http.get('/reservations/shared', { params });
    }

    /**
     * Inscrit l'administrateur courant à un ou plusieurs calls
     * @param {any} body Un object contenant 1 paramètre : Un tableau regroupant la liste des id des calls auxquels on souhaite s'inscrire
     * @returns {Observable} Un observable d'un booléen si l'inscription aux calls s'est déroulé correctement
     */
    @log() subscribeCalls(body: any): Observable<any> {
        return this.http.post('/reservations/register', body);
    }

    /**
     * Crée un call
     * @param {any} body Un object contenant 1 paramètre : Un objet représentant un nouveau call
     * @returns {Observable} Un observable d'un booléen si la création du call s'est déroulé correctement
     */
    @log() createCall(body: any): Observable<any> {
        body.roles = [];

        return this.http.post('/reservations', body);
    }

    /**
     * Met à jour un call
     * @param {number} callId L'identifiant du call que l'on souhaite mettre à jour
     * @param {any} body Un object contenant 1 paramètre : Un objet représentant un call existant
     * @returns {Observable} Un observable d'un booléen si la mise à jour du call s'est déroulé correctement
     */
    @log() updateCall(callId: number, body: any): Observable<any> {
        body.roles = [];
        return this.http.put('/reservations/' + callId, body);
    }

    /**
     * Supprime un call
     * @param {number} id L'identifiant du call à récuperer
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression s'est déroulé correctement
     */
    @log() deleteCall(id: number): Observable<any> {
        return this.http.delete('/reservations/' + id);
    }

    /**
     * Fonction de rappel d'un call.
     * @param id l'id du call a rappeler.
     */
    @log() remindCall(id: number): Observable<any> {
        return this.http.get('/reservations/reminder/' + id);
    }

    @log() exportCalls(params: any): Observable<any> {
        return this.http.get('/reservations/history', { params });
    }

    @log() toggleMuteCall(call: Call): Observable<any> {
        return this.http.get(`/reservations/${call.id}/${call.isMuted ? 'unmute' : 'mute'}`);
    }

    @log() exportPolls(call: Call): Observable<any> {
        return this.http
            .get(`/reservations/${call.id}/polls`, { responseType: 'blob', observe: 'response' })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    const blob = new Blob([response.body], {
                        type: 'text/csv; charset=iso-8859-1;'
                    });
                    const date = new Date(call.starttime).toLocaleDateString();
                    saveAs(blob, `${date}_${call.name}_historique_presence.csv`);
                    return response;
                })
            );
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour la liste des calls
     */
    refreshCalls() {
        this.refreshListCalls.next('');
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour le call actuel
     */
    refreshCurrentCall() {
        this.refreshCall.next();
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour le call actuel
     */
    resetCurrentCall() {
        this.resetCall.next('');
    }
}
