<div class="container" [ngClass]="{ opened: isCallOpened() }">
    <div class="main-container">
        <div class="call-name">{{ call.name }}</div>
        <div class="author-container">
            <span class="icon" [ngClass]="getUserIcon(call.owner)"></span>
            <span class="author-name">{{ getAuthorName() }}</span>
        </div>
        <div class="member-count">{{ call.membersCount + 1 }}</div>
    </div>
    <span
        class="icon-down"
        [ngClass]="{ opened: isCallOpened() }"
        (click)="toggleCall($event)"
    ></span>

    <div id="call-container" [ngClass]="{ opened: isCallOpened() }">
        <div class="name-user-container" *ngFor="let user of call.members">
            <span class="icon" [ngClass]="getUserIcon(user)"></span>
            <span class="name">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span>
        </div>
        <div class="name-group-container" *ngFor="let group of call.groups">
            <div class="group-header">
                <span class="icon-groupe"></span>
                <span class="name">{{ group.name }}</span>
            </div>
            <div class="user-container" *ngFor="let user of group.members">
                <span class="icon" [ngClass]="getUserIcon(user)"></span>
                <span class="name-user"
                    >{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span
                >
            </div>
        </div>
    </div>
</div>
