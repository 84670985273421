import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ApplicationRef } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatNativeDateModule
} from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavComponent } from './components/nav/nav.component';
import { NavEntryComponent } from './components/nav/nav-entry/nav-entry.component';
import { MainComponent } from './components/main/main.component';
import { MainEntryComponent } from './components/main/main-entry/main-entry.component';
import { WorkspaceCarouselComponent } from './components/workspace-carousel/workspace-carousel.component';
import { FlashMessageComponent } from './components/flash-message/flash-message.component';
import { LoadingComponent } from './components/loading/loading.component';

import { CreateCallComponent } from './dialogs/create-call/create-call.component';
import { CreateCallEntryComponent } from './dialogs/create-call/create-call-entry/create-call-entry.component';
import { SubscribeCallComponent } from './dialogs/subscribe-call/subscribe-call.component';
import { SubscribeCallEntryComponent } from './dialogs/subscribe-call/subscribe-call-entry/subscribe-call-entry.component';
import { ErrorComponent } from './dialogs/error/error.component';
import { DeleteCallComponent } from './dialogs/delete-call/delete-call.component';
import { ExportCallComponent } from './dialogs/export-call/export-call.component';
import { SharedCallComponent } from './dialogs/shared-call/shared-call.component';
import { SharedCallEntryComponent } from './dialogs/shared-call/shared-call-entry/shared-call-entry.component';
import { SuccessComponent } from './dialogs/success/success.component';

import { ConfigService } from './services/config.service';
import { LoginService } from './services/login.service';
import { DialogService } from './services/dialog.service';

import { DropdownModule } from 'libs/uimm-dropdown/src/public-api';
import { TooltipModule } from 'libs/uimm-tooltip/src/public-api';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { CookieService } from 'ngx-cookie-service';

import { isIE } from 'mobile-device-detect';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { LogInterceptor } from './interceptors/log.interceptor';
import { EndpointInterceptor } from './interceptors/endpoint.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

const appRoutes: Routes = [
    {
        path: 'call/:id',
        component: MainComponent
    },
    {
        path: '',
        component: WorkspaceCarouselComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NavComponent,
        NavEntryComponent,
        MainComponent,
        MainEntryComponent,
        CreateCallComponent,
        CreateCallEntryComponent,
        SubscribeCallEntryComponent,
        SubscribeCallComponent,
        ErrorComponent,
        DeleteCallComponent,
        WorkspaceCarouselComponent,
        SharedCallComponent,
        SharedCallEntryComponent,
        SuccessComponent,
        ExportCallComponent,
        FlashMessageComponent,
        LoadingComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatMenuModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        DropdownModule,
        TooltipModule,
        LoadingBarModule,
        LoadingBarHttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        CommonModule
    ],
    bootstrap: [],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        CookieService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private configService: ConfigService,
        private loginService: LoginService,
        private dialogService: DialogService
    ) {}

    ngDoBootstrap(app) {
        if (isIE) {
            window.location.href = 'https://cdn.easi-training.fr/assets/html/ie.html';
        }
        const getConfig = new XMLHttpRequest();
        getConfig.onload = (data: any) => {
            const response = JSON.parse(data.target.response);
            this.configService.setConfig(response);
            this.loginService.postCookies().subscribe((dataFromPost: number) => {
                this.loginService.getCookies().subscribe((dataFromGet: number) => {
                    if (dataFromPost === dataFromGet) {
                        if (response.profile) {
                            this.getProfileConfigSetConfigAndBootstrap(response, app);
                        } else {
                            this.setConfigAndBootStrap(response, app);
                        }
                    } else {
                        this.dialogService.openErrorDialog(
                            'Veuillez autoriser les cookies pour poursuivre votre navigation sur easi'
                        );
                    }
                });
            });
        };
        getConfig.open('get', `./assets/config/config.json?${Date.now()}`, true);
        getConfig.send();
    }

    private setConfigAndBootStrap(config: object, app: ApplicationRef): void {
        this.configService.setConfig(config);
        app.bootstrap(AppComponent);
        this.loginService.getCurrentUserCall().subscribe(() => {});
    }

    private getProfileConfigSetConfigAndBootstrap(
        response: { profile: string },
        app: ApplicationRef
    ): void {
        const getProfileConfig = new XMLHttpRequest();

        getProfileConfig.onload = (data2: any) => {
            const profileResponse = JSON.parse(data2.target.response);
            this.setConfigAndBootStrap(
                {
                    ...response,
                    profileResponse
                },
                app
            );
        };
        getProfileConfig.open('get', `./assets/config/${response.profile}.json?${Date.now()}`);
        getProfileConfig.send();
    }
}
