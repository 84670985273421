import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, fromEvent, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { LoginService } from './login.service';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux utilisateurs/groupes
 */
@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private http: HttpClient, private loginService: LoginService) {}

    /**
     * Récupère la liste des structures
     * @returns {Observable} Un observable de la liste des structures
     */
    @log() getStructures(): Observable<any> {
        const iframe: any = document.getElementById('header-container');

        iframe.contentWindow.postMessage('getStructures', '*');

        return fromEvent(window, 'message').pipe(
            filter((data: any) => {
                return data.data.structures !== undefined;
            }),
            tap({
                error: (error) => {
                    this.loginService.isAuthenticated(error);
                }
            })
        );
    }

    /**
     * @deprecated désactivé dans les routes back
     * Récupère un groupe spécifique
     * @param {number} id L'identifiant du groupe que l'on souhaite récuperer
     * @returns {Observable} Un observable du groupe récupéré
     */
    @log() getGroup(id: number): Observable<any> {
        return this.http.get('/groups/' + id);
    }

    /**
     * Récupère une liste de groupe
     * @param {any} params Un objet contenant 2 paramètres : structureid et search
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getGroups(params: any): Observable<any> {
        if (params.structureid === 0) {
            return from([[]]);
        }

        return this.http.get('/groups', { params });
    }

    /**
     * Récupère le nombre de groupe dans la structure sélectionné
     * @param {any} params Un objet contenant 2 paramètres : structureid
     * @returns {Observable} Un observable du nombre de groupe dans la structure sélectionné
     */
    @log() getGroupsCount(params: any): Observable<any> {
        if (params.structureid === 0) {
            return from([{ count: 0 }]);
        }

        return this.http.get('/groups?count=true', { params });
    }

    /**
     * Récupère une liste d'utilisateurs
     * @param {any} params Un objet contenant 1 paramètre : structureid, search, role
     * @returns {Observable} Un observable des utilisateurs correspondant aux paramètre
     */
    @log() getUsers(params: any): Observable<any> {
        return this.http.get('/users', { params });
    }

    /**
     * Récupère le nombre d'utilisateurs correspondant aux paramètres
     * @param {any} params Un objet contenant 2 paramètre : structureid et role
     * @returns {Observable} Un observable du nombre d'utilisateurs correspondant aux paramètres
     */
    @log() getUsersCount(params: any): Observable<any> {
        return this.http.get('/users?count=true', { params });
    }

    /**
     * @deprecated : route désactivée niveau back
     * Récupère un utilisateur spécifique
     * @param {number} id L'identifiant de l'utilisateur que l'on souhaite récuperer
     * @returns {Observable} Un observable de l'utilisateur récupéré
     */
    @log() getUser(id: number): Observable<any> {
        return this.http.get('/users/' + id);
    }
}
