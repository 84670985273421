<div class="header">
    <span class="icon-Warning"></span>
    <span class="label">Succès</span>
</div>
<mat-dialog-content class="content">
    {{ successMessage }}
</mat-dialog-content>
<div class="footer">
    <div class="create-button" (click)="closeDialog()">OK</div>
</div>
