import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';

import { CallService } from '../../services/call.service';

@Component({
    selector: 'app-delete-call',
    templateUrl: './delete-call.component.html',
    styleUrls: ['./delete-call.component.scss']
})
export class DeleteCallComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<DeleteCallComponent>,
        private callService: CallService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    openErrorDialog: Subject<string> = new Subject();

    setWaiting: boolean;

    subscriptions = new Subscription();

    ngOnInit() {
        this.setWaiting = false;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * supprime le call.
     */
    deleteCall() {
        if (this.setWaiting === false) {
            this.setWaiting = true;
            this.subscriptions.add(
                this.callService.deleteCall(this.data.callId).subscribe(
                    () => {
                        this.callService.refreshCalls();
                        this.callService.resetCurrentCall();
                        this.closeDialog();
                        this.setWaiting = false;
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.next(error.error.userMessage);
                        this.setWaiting = false;
                    }
                )
            );
        }
    }

    /**
     * ferme la fenêtre de création de call.
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
