<div class="main" [ngClass]="{ hidden: reduced }">
    <div class="header">
        <div class="calls-count">
            <strong>{{ totalCallsCount }}</strong> calls
        </div>
        <div class="right-container">
            <div class="search-container">
                <div
                    class="search-input-container"
                    [ngClass]="{ active: activeSearch, empty: isSearchEmpty() }"
                >
                    <input
                        class="library-search-field"
                        placeholder="Chercher..."
                        [(ngModel)]="search"
                        (keyup.enter)="refreshDataAndHistory()"
                        #searchElement
                    />
                    <span class="icon-Fermerdetails" (mousedown)="resetSearch($event)"></span>
                    <span
                        class="icon-search"
                        [ngClass]="{ blue: !isSearchEmpty() }"
                        (click)="refreshDataAndHistory()"
                        *ngIf="activeSearch"
                    ></span>
                    <span
                        class="icon-search"
                        [ngClass]="{ blue: !isSearchEmpty() }"
                        (click)="toggleActiveSearch()"
                        *ngIf="!activeSearch"
                    ></span>
                </div>
            </div>
            <div
                class="icon-minimizebloc"
                [ngClass]="{ inversed: reduced }"
                (click)="toggleReduce()"
            ></div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="container">
        <div class="started-calls" *ngIf="showCreateButton()">
            <div class="title" (click)="toggleAuthorCalls()">
                <span class="icon-partage-avec-autres"></span>
                <span class="label">Mes calls ({{ startedCallsCount }})</span>
                <span
                    class="icon-down"
                    *ngIf="authorsCalls?.length > 0"
                    [ngClass]="{ open: !isClosedAuthorCalls }"
                ></span>
            </div>
            <div
                id="author-calls-container"
                [@slideToggle]="isClosedAuthorCalls ? 'closed' : 'open'"
            >
                <app-nav-entry
                    *ngFor="let call of authorsCalls"
                    [call]="call"
                    (click)="selectCall(call.id, false)"
                    [selectedCall]="selectedCall"
                    [type]="'author'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second" *ngIf="showCreateButton()"></div>
        <div class="member-calls">
            <div class="title" (click)="toggleMemberCalls()">
                <span class="icon-partage-avec-moi"></span>
                <span class="label">Autres calls ({{ memberCallsCount }})</span>
                <span
                    class="icon-down"
                    *ngIf="membersCalls?.length > 0"
                    [ngClass]="{ open: !isClosedMemberCalls }"
                ></span>
            </div>
            <div
                id="member-calls-container"
                [@slideToggle]="isClosedMemberCalls ? 'closed' : 'open'"
            >
                <app-nav-entry
                    *ngFor="let call of membersCalls"
                    [call]="call"
                    (click)="selectCall(call.id, false)"
                    [selectedCall]="selectedCall"
                    [type]="'member'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second"></div>
        <div class="history-calls">
            <div class="title" (click)="toggleHistoricCalls()">
                <span class="icon-easi-call-line"></span>
                <span class="label">Historique</span>
                <span
                    class="icon-down"
                    *ngIf="historicCalls?.length > 0"
                    [ngClass]="{ open: !isClosedHistoricCalls }"
                ></span>
            </div>
            <div
                id="historic-calls-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageHistory()"
                [@slideToggle]="isClosedHistoricCalls ? 'closed' : 'open'"
            >
                <div class="filters" [@slideToggle]="isClosedHistoricCalls ? 'closed' : 'open'">
                    <div class="recording-selector" (click)="toggleHistoryRecordingSelector()">
                        Contient un enregistrement
                        <span
                            [ngClass]="
                                getHistoryParams.with_recording ? 'icon-selected' : 'icon-select'
                            "
                        ></span>
                    </div>
                    <div class="dates-filter" (click)="toggleHistoryDatesSelector()">
                        Date <span class="icon-deroulant"></span>
                        <div
                            class="dates-filter_dropdown"
                            [ngClass]="{ opened: datesFilterOpened }"
                        >
                            Du
                            <input
                                class="date-input"
                                [(ngModel)]="getHistoryParams.startdate"
                                [matDatepicker]="startdateInput"
                                (ngModelChange)="updateHistory()"
                                (focus)="startdateInput.open()"
                            />
                            <mat-datepicker #startdateInput></mat-datepicker>
                            au
                            <input
                                class="date-input"
                                [(ngModel)]="getHistoryParams.enddate"
                                [matDatepicker]="enddateInput"
                                (ngModelChange)="updateHistory()"
                                (focus)="enddateInput.open()"
                                [max]="today"
                            />
                            <mat-datepicker #enddateInput></mat-datepicker>
                        </div>
                    </div>
                </div>
                <div class="placeholder"></div>
                <app-loading *ngIf="isHistoryLoading"></app-loading>
                <app-nav-entry
                    *ngFor="let call of historicCalls"
                    [call]="call"
                    (click)="selectCall(call.id, true)"
                    [selectedCall]="selectedCall"
                    [type]="'history'"
                ></app-nav-entry>
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="check-config">
        <div class="button-subscribe" (click)="checkConfig()">Vérifier ma configuration</div>
    </div>
    <div class="export-call">
        <div class="button-subscribe" *ngIf="showExportButton()" (click)="openExportCallDialog()">
            Export CSV des calls
        </div>
    </div>
    <div class="subscribe-call">
        <div
            class="button-subscribe"
            *ngIf="showSubscribeButton()"
            (click)="openSubscribeCallDialog()"
        >
            M'inscrire à un call
        </div>
    </div>

    <div class="add-call" *ngIf="showCreateButton()" (click)="openCreateCallDialog()">
        <span class="icon-plus"></span>
    </div>
</div>
