import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { UsersService } from '../../../services/users.service';
import { DialogService } from '../../../services/dialog.service';
import { Subscription } from 'rxjs';
import { slideToggle } from 'src/app/animations/slide-toggle';

/**
 *  composant gérant l'affichage des utilisateurs/groupes dans la fenêtre de création de call
 */
@Component({
    selector: 'app-create-call-entry',
    templateUrl: './create-call-entry.component.html',
    styleUrls: ['./create-call-entry.component.scss'],
    animations: [slideToggle]
})
export class CreateCallEntryComponent implements OnInit, OnDestroy {
    /**
     * @param {any} element l'user recu en input.
     * @param {string} role le role de l'user recu en input.
     * @param {EventEmitter} roleChange emet un evenement si le role change.
     * @param {boolean} selected l'user selectionne.
     * @param {EventEmitter} selectedChange emet un evenement si l'user selectionne change.
     * @param {boolean} openedGroup est-ce qu'une entree de type groupe est ouverte ou pas.
     */
    @Input() element: any;

    @Input() role: string;
    @Output() roleChange: EventEmitter<any> = new EventEmitter();

    @Input() selected: boolean;
    @Output() selectedChange: EventEmitter<any> = new EventEmitter();

    openedGroup: boolean;

    subscriptions = new Subscription();

    constructor(private usersService: UsersService, private dialogService: DialogService) {}

    /**
     *  indique l'icône à afficher selon le type et le rôle de l'element actuel.
     * @returns {string} La classe CSS correspondante à l'icône à afficher.
     */
    getIconStyle(): string {
        if (this.element.roles === undefined) {
            return 'icon-groupe';
        } else {
            return this.getUserIcon(this.element);
        }
    }

    getUserIcon(user) {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     *  renvoie le nom à afficher pour l'élement actuel.
     * @returns {string} Renvoie le nom du group si l'élement est un groupe, ou le prénom et le nom si l'élement est un utilisateur.
     */
    getElementName(): string {
        if (this.element.name) {
            return this.element.name;
        } else {
            return this.element.lastname.toUpperCase() + ' ' + this.element.firstname;
        }
    }

    /**
     *  indique si l'élement actuel possède le rôle de modérateur.
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire.
     */
    isAttendee(): boolean {
        return this.role === 'attendee';
    }

    /**
     *  indique si l'élement actuel possède le rôle de modérateur.
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire.
     */
    isModerator(): boolean {
        return this.role === 'moderator';
    }

    /**
     *  modifie le rôle de l'utilisateur dans le call.
     * @param {boolean} boolean Un booléen indiquant si l'utilisateur doit avoir les rôles de modérateur.
     */
    toggleRole() {
        if (this.role === 'moderator') {
            this.role = 'attendee';
        } else if (this.role === 'attendee') {
            this.role = 'moderator';
        }
        this.roleChange.emit(this.role);
    }

    /**
     *  sélectionne/déselectionne l'element actuel.
     */
    toggleSelected() {
        this.selected = !this.selected;
        this.selectedChange.emit(this.selected);
    }

    /**
     *  indique si l'élement actuel est sélectionné ou non.
     */
    isElementSelected(): boolean {
        return this.selected;
    }

    /**
     *  indique si le groupe est déplié.
     */
    isGroupOpened() {
        return this.openedGroup;
    }

    /**
     * @param {event} $event L'évenement JS émi lors du clic.
     *  déplie le groupe sélectionné.
     */
    showGroup(): void {
        if (this.element.users === undefined) {
            this.subscriptions.add(
                this.usersService.getGroup(this.element.id).subscribe(
                    (data: any) => {
                        this.element = data;
                        this.openedGroup = !this.openedGroup;
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                )
            );
        } else {
            this.openedGroup = !this.openedGroup;
        }
    }

    ngOnInit() {
        this.openedGroup = false;
        if (this.role === undefined) {
            this.role = 'attendee';
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
