<div class="header">
    <div class="icon-calendrier"></div>
    <div class="title">Historique des calls</div>
    <div class="icon-close" (click)="closeDialog()"></div>
</div>
<mat-dialog-content class="content">
    <lib-uimm-dropdown
        [label]="'Structure'"
        [(items)]="structures"
        [labelWidth]="'300px'"
        *ngIf="isNationalAdmin()"
    ></lib-uimm-dropdown>
    <div class="separator"></div>
    <div class="main-body">
        <div class="datepicker-start-container">
            <div class="label">Date de début</div>
            <input
                type="text"
                class="start-date-input"
                [matDatepicker]
                [(ngModel)]="startDate"
                placeholder="Date"
            />
            <mat-calendar
                [minDate]="getMinDate()"
                [maxDate]="endDate"
                [(selected)]="startDateInput"
                (selectedChange)="updateStartDate()"
            ></mat-calendar>
        </div>
        <div class="datepicker-end-container">
            <div class="label">Date de fin</div>
            <input
                type="text"
                class="end-date-input"
                [matDatepicker]
                [(ngModel)]="endDate"
                placeholder="Date"
            />
            <mat-calendar
                [(selected)]="endDateInput"
                (selectedChange)="updateEndDate()"
                [minDate]="startDate"
                [maxDate]="today"
            ></mat-calendar>
        </div>
    </div>
    <div class="warning-internal-teacher" *ngIf="isInternalTeacher()">
        Vous pouvez télécharger l'export sur les 30 derniers jours des calls dont vous êtes l'auteur
        ou le modérateur.
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="button disabled" *ngIf="isDisabled()">{{ getPlaceholder() }}</div>
    <a class="button" *ngIf="!isDisabled()" [href]="getExportURL()">Exporter</a>
</div>
