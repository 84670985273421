<div class="call-container" *ngIf="call">
    <div class="data" [ngClass]="{ 'call-ended': getCallState() === 'ended' }">
        <div class="header">
            <span class="icon-partage-avec-autres"></span>
            <span class="title">{{ call.name }}</span>
            <span class="date">{{ call.starttime | date: 'dd/MM/yyyy' }}</span>
            <span class="duration">
                {{ call.starttime | date: 'HH' }}h{{ call.starttime | date: 'mm' }}-{{
                    call.endtime | date: 'HH'
                }}h{{ call.endtime | date: 'mm' }}
            </span>
        </div>
        <div class="agenda">
            <div class="label">Ordre du jour</div>
            <div class="value" [innerHtml]="call.description"></div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="phone">
        <ng-container *ngIf="call.connect_by_phone && call.connect_by_phone_number">
            Pour rejoindre la conversation par téléphone, composer le
            <span class="number">{{ call.connect_by_phone_number }}</span> puis saisir le numéro de
            conférence <span class="pink"> {{ call.connect_by_phone_pin }}</span>
        </ng-container>
        <ng-container *ngIf="call.connect_by_phone && !call.connect_by_phone_number">
            Les informations pour rejoindre la conversation par téléphone seront affichés 1 heure
            avant le début de la conférence.
        </ng-container>
        <ng-container *ngIf="!call.connect_by_phone">
            Cette conférence n'est pas configurée pour accepter les connexions via téléphone
        </ng-container>
    </div>
    <div class="members-container">
        <div class="header">
            <span class="members-count" [innerHTML]="getMembersCounters()"></span>
            <span class="role">Rôle</span>
        </div>
        <app-main-entry
            *ngFor="let user of getOrderedUsers()"
            [element]="user"
            [type]="'user'"
            [connected]="call.connected"
        ></app-main-entry>
        <app-main-entry
            *ngFor="let group of getOrderedGroups()"
            [element]="group"
            [type]="'group'"
            [connected]="call.connected"
        ></app-main-entry>
        <app-main-entry
            *ngFor="let guest of getOrderedGuests()"
            [element]="guest"
            [type]="'guest'"
            [connected]="call.connected"
        ></app-main-entry>
    </div>

    <div class="separator"></div>

    <div class="button-container" *ngIf="getCallState() !== 'ended'">
        <!-- HOTFIX#288233 -->

        <!-- <mat-checkbox
            class="checkbox recording"
            *ngIf="getCallState() === 'started'"
            [(ngModel)]="recording"
        >
            <span [class.checked]="recording"
                >Pour pouvoir participer à ce call, j'accepte qu'un enregistrement puisse être
                réalisé.</span
            >
        </mat-checkbox> -->

        <mat-checkbox
            class="checkbox recording"
            *ngIf="getCallState() === 'started' || getCallState() === 'joined'"
            [(ngModel)]="recording"
        >
            <span [class.checked]="recording"
                >Pour pouvoir participer à ce call, j'accepte qu'un enregistrement puisse être
                réalisé.
            </span>
        </mat-checkbox>

        <!-- END HOTFIX#288233 -->

        <span class="flash-warning" *ngIf="showFlashWarning() && recording"
            >Préferez l'utilisation des navigateurs Google Chrome ou Mozilla Firefox pour une
            meilleure expérience
        </span>
        <div class="join-call">
            <!-- HOTFIX#288233 -->

            <!-- <div
                class="join-button"
                (click)="joinCall()"
                [ngClass]="{
                    disabled:
                        getCallState() !== 'started' || getCallState() === 'joined' || !recording
                }"
            >
                <span class="label" *ngIf="getCallState() === 'waiting'"
                    >Ce call n'a pas encore commencé</span
                >

                <span class="label" *ngIf="getCallState() === 'started'">Rejoindre le call</span>
                <span class="label" *ngIf="getCallState() === 'joined'"
                    >Vous avez déja rejoint ce call</span
                >

                <span class="icon-Envoyer" *ngIf="getCallState() === 'started'"></span>
            </div> -->

            <div
                class="join-button"
                (click)="joinCall()"
                [ngClass]="{
                    disabled:
                        (getCallState() !== 'started' && getCallState() !== 'joined') || !recording
                }"
            >
                <span class="label" *ngIf="getCallState() === 'waiting'"
                    >Ce call n'a pas encore commencé
                </span>

                <span
                    class="label"
                    *ngIf="getCallState() === 'started' || getCallState() === 'joined'"
                    >Rejoindre le call
                </span>

                <span class="icon-Envoyer" *ngIf="getCallState() === 'started'"></span>
            </div>

            <!-- END HOTFIX#288233 -->
        </div>
        <div class="refresh-call">
            <div
                class="join-button"
                (click)="refreshCall()"
                [ngClass]="{ disabled: timerEnabledRefresh > 0 }"
            >
                <span class="label">Rafraîchir la page</span>
                <span *ngIf="timerEnabledRefresh > 0"> ({{ timerEnabledRefresh }})</span>
                <span class="icon-Nouvelleversioncontenu"></span>
            </div>
        </div>
    </div>

    <div
        class="recording-container"
        *ngIf="getCallState() === 'ended' && getRecordings().length > 0"
    >
        <div class="header">
            <span class="icon-enregistrement"></span>
            <span class="label"
                >Ce call a été enregistré. Les enregistrements sont
                <strong
                    >disponibles (une fois le traitement terminé par le serveur) pendant 100 jours
                </strong>
                après la tenue du call.
            </span>
        </div>
        <div class="recording" *ngFor="let recording of getRecordings()">
            <span class="icon-enregistrement"></span>
            <span class="name">{{ call.name }}</span>
            <span class="date">{{ recording.startTime | date: 'dd/MM/yyyy' }}</span>
            <span class="hour"
                >{{ recording.startTime | date: 'HH:mm' }}-{{
                    recording.endTime | date: 'HH:mm'
                }}</span
            >
            <span class="icons">
                <span
                    class="icon-Lancer"
                    (click)="launchRecording(recording)"
                    tooltip="Voir l'enregistrement"
                    tooltipPosition="left"
                    tooltipTextColor="#f36"
                    tooltipColor="#fff"
                ></span>
            </span>
        </div>
    </div>
</div>
