import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { CreateCallComponent } from '../dialogs/create-call/create-call.component';
import { SubscribeCallComponent } from '../dialogs/subscribe-call/subscribe-call.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { DeleteCallComponent } from '../dialogs/delete-call/delete-call.component';
import { SharedCallComponent } from '../dialogs/shared-call/shared-call.component';
import { SuccessComponent } from '../dialogs/success/success.component';
import { ExportCallComponent } from '../dialogs/export-call/export-call.component';
import { Observable, Subscription } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private dialog: MatDialog, private loginService: LoginService) {
        this.subscriptions.add(
            this.loginService.closeAllDialogs$.subscribe((data: boolean) => {
                if (data) {
                    this.closeAllDialogs();
                }
            })
        );
    }

    private subscriptions: Subscription = new Subscription();

    /**
     * Ouvre la fenetre de création de call
     * @param {number} callId L'identifiant optionnel du call que l'utilisateur souhaite modifier
     * @param {number} groupId Les identifiants optionnels des groupes que l'utilisateur souhaite ajouter au call
     * @param {number} userId Les identifiants optionnels des utilisateurs que l'utilisateur souhaite ajouter au call
     */
    openCreateCallDialog(callId?: number, groupId?: number, userId?: number, duplicate?: boolean) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '650px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.panelClass = 'overflow-scroll-for-call-creation';
        dialogConfig.data = {};
        if (callId) {
            dialogConfig.data.callId = callId;
        }
        if (userId) {
            dialogConfig.data.userId = userId;
        }
        if (groupId) {
            dialogConfig.data.groupId = groupId;
        }
        if (duplicate) {
            dialogConfig.data.duplicate = duplicate;
        }

        const ref: MatDialogRef<CreateCallComponent> = this.dialog.open(
            CreateCallComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * Ouvre la fenetre d'enregistrement de call
     * @param {number} callId Les identifiants optionnels des calls auxquels l'utilisateur souhaite s'enregistrer
     */
    openSubscribeCallDialog(callId?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '900px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (callId) {
            dialogConfig.data.callId = callId;
        }

        const ref: MatDialogRef<SubscribeCallComponent> = this.dialog.open(
            SubscribeCallComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * Ouvre la fenetre d'erreur
     * @param {string} content Le message d'erreur que l'on souhaite afficher
     */
    openErrorDialog(content?: string) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        this.dialog.open(ErrorComponent, dialogConfig);
    }

    /**
     * Ouvre la fenetre d'erreur
     * @param {string} content Le message d'erreur que l'on souhaite afficher
     */
    openSuccessDialog(content?: string) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        this.dialog.open(SuccessComponent, dialogConfig);
    }

    /**
     * Ouvre la fenetre de calls partagés
     * @param {number} callId L'identifiant du call que l'utilisateur souhaite suprimer
     * @returns {Observable} le call sélectionné ou undefined
     */
    openSharedCallDialog(type: string, id: number): Observable<any> {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (type) {
            dialogConfig.data.type = type;
        }
        if (id) {
            dialogConfig.data.id = id;
        }

        const ref: MatDialogRef<SharedCallComponent> = this.dialog.open(
            SharedCallComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
        return ref.afterClosed();
    }

    /**
     * Ouvre la fenetre d'erreur
     * @param {number} callId L'identifiant du call que l'utilisateur souhaite suprimer
     */
    openDeleteCallDialog(callId: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (callId) {
            dialogConfig.data.callId = callId;
        }

        const ref: MatDialogRef<DeleteCallComponent> = this.dialog.open(
            DeleteCallComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * Ouvre la fenetre d'erreur
     * @param {string} content Le message d'erreur que l'on souhaite afficher
     */
    openExportCallDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        this.dialog.open(ExportCallComponent, dialogConfig);
    }

    /**
     * ferme toutes les modales ouvertes (utilisé par is_still_connected)
     */
    closeAllDialogs(): void {
        this.dialog.closeAll();
    }
}
